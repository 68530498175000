export default class InView {
  constructor(el) {
    this.el = el;
    this.threshold = parseFloat(el.getAttribute('data-threshold'));

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.el.classList.add('inView');
        }
      });
    };
    const options = {
      threshold: this.threshold ? this.threshold : 0,
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(this.el);
  }
}
