import Plyr from 'plyr';

export default class VideoEmbed {
  constructor(el) {
    this.el = el;
    this.playBtn = el.querySelector('.js-videoEmbedPlay');
    this.loop = el.querySelector('.js-videoEmbedLoop');
    this.posterVisible = true;

    const plyrEl = el.querySelector('.js-videoEmbedPlyr');
    this.player = new Plyr(plyrEl); // eslint-disable-line

    this.playBtn.addEventListener('click', () => {
      this.el.removeEventListener('mouseover', this.handleMouseover);
      this.el.removeEventListener('mouseout', this.handleMouseout);
      this.loop.classList.add('hide');
      this.player.play();
    });

    this.el.addEventListener('mouseover', this.handleMouseover);

    this.el.addEventListener('mouseout', this.handleMouseout);
  }
}
