export default class InView {
  constructor(el) {
    this.el = el;
    this.button = el.querySelector('.js-expandBtn');

    this.button.addEventListener('click', () => {
      this.el.classList.toggle('visible');
      const span = this.button.querySelector('span');
      const viewAll = span.dataset.viewAll;
      const viewLess = span.dataset.viewLess;
      if (span.innerHTML === viewAll) {
        span.innerHTML = viewLess;
      } else {
        span.innerHTML = viewAll;
      }
    });
  }
}
