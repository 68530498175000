export default class PlayInLightBox {
  constructor(el, plyr) {
    this.el = el;
    this.plyr = plyr;
    this.lightbox = document.querySelector('#js-lightbox');
    this.close = this.lightbox.querySelector('.js-close');

    this.el.addEventListener('click', () => {
      this.lightbox.classList.add(this.el.getAttribute('data-aspect-ratio'));
      this.lightbox.classList.add('active');
      const source = {
        type: 'video',
        sources: [
          {
            src: this.el.getAttribute('data-vimeo-link'),
            provider: 'vimeo',
          },
        ],
      };
      this.plyr.source = source;
      console.log(this.plyr);
      this.plyr.autoplay = true;
    });

    this.close.addEventListener('click', () => {
      this.lightbox.classList.remove(this.el.getAttribute('data-aspect-ratio'));
      this.lightbox.classList.remove('active');
      this.plyr.pause();
    });
  }
}
