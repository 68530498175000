import BackToTop from '../components/BackToTop';
import ExpandableContent from '../components/ExpandableContent';
import InView from '../components/InView';
import PlayInLightbox from '../components/PlayInLightbox';
import Plyr from 'plyr';
import SlideyImages from '../components/SlideyImages';
import VideoEmbed from '../components/VideoEmbed';
import nav from '../components/nav';
import plyr from '../plugins/plyr';

// Detect touch
const ua = window.navigator.userAgent;
const isMobile = ua.match(
  /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i
);
if ('ontouchstart' in document.documentElement && isMobile) {
  document.body.classList.add('touch');
}

// Elements
const videoEmbedEls = document.querySelectorAll('.js-videoEmbed');
const inViewEls = document.querySelectorAll('.js-inView');
const slideyEls = document.querySelectorAll('.js-slidey');
const expandableEls = document.querySelectorAll('.js-expand');
const playInLightboxEls = document.querySelectorAll('.js-playInLightbox');
const backToTopEl = document.querySelector('#js-backToTop');

export default {
  init() {
    plyr();
    nav();
    videoEmbedEls.forEach((el) => {
      new VideoEmbed(el);
    });
    inViewEls.forEach((el) => {
      new InView(el);
    });
    slideyEls.forEach((el) => {
      new SlideyImages(el);
    });
    expandableEls.forEach((el) => {
      new ExpandableContent(el);
    });
    const lightboxPlyrEl = document.querySelector('#js-lightboxPlyr');
    if (lightboxPlyrEl) {
      const lightboxPlyr = new Plyr(lightboxPlyrEl);
      playInLightboxEls.forEach((el) => {
        new PlayInLightbox(el, lightboxPlyr);
      });
    }
    if (backToTopEl) {
      new BackToTop(backToTopEl);
    }
  },

  finalize() {},
};
