import mapValues from '../util/mapValues';

export default class SlideyImages {
  constructor(el) {
    this.el = el;
    this.img1 = el.querySelectorAll('.js-slideyImg')[0];
    this.img2 = el.querySelectorAll('.js-slideyImg')[1];
    this.transYWithInertia = null;
    this.loop = this.loop.bind(this);

    requestAnimationFrame(this.loop);
  }

  loop() {
    const levelPoint = window.innerHeight * 0.25;
    const transY = mapValues(
      this.el.getBoundingClientRect().top,
      window.innerHeight,
      levelPoint,
      70,
      0
    );

    if (this.transYWithInertia === null) {
      this.transYWithInertia = transY;
    } else {
      this.transYWithInertia += (transY - this.transYWithInertia) / 5;
    }

    this.img1.style.transform = `translate3d(0, ${this.transYWithInertia}px, 0`;
    // If mobile slide the second image up, if desktop slide it down
    if (window.innerWidth < 768) {
      this.img2.style.transform = `translate3d(0, ${this.transYWithInertia}px, 0`;
    } else {
      this.img2.style.transform = `translate3d(0, -${this.transYWithInertia}px, 0`;
    }

    requestAnimationFrame(this.loop);
  }
}
